
<template>
  <section class="home-slider">
    <b-container fluid>
      <b-row>
        <b-col lg="6">
          <home-slider></home-slider>
        </b-col>
        <b-col lg="6">
          <v-runtime-template
            :template="homebannerblock.content"
          ></v-runtime-template>

          <!-- <div class="home-banner-block">
            <div class="single-block up">
              <b-link href="/producten/tuingereedschap/grasmaaiers">
                <img
                  src="@/esf_antarctica_1ekeus/assets/imgs/banner-up.png"
                  alt="banner up"
                  style="max-height: 205px"
                />
              </b-link>
            </div>
            <div class="single-block down">
              <b-link href="/producten/tuingereedschap/hogedrukreinigers">
                <img
                  src="@/esf_antarctica_1ekeus/assets/imgs/banner-down.png"
                  alt="banner down"
                  style="max-height: 205px"
                />
              </b-link>
            </div>
          </div> -->

        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import HomeSlider from "@/base/core/components/home/HomeSlider.vue";

export default {
  components: { 
    HomeSlider, 
    VRuntimeTemplate 
    },
  computed: {
    homebannerblock() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "home_slider_block"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>